import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";

import { uiActions } from "../reducers/ui-slice";
import httpService from "../../services/http.service";

export const getAllPo = createAsyncThunk(
  "getAllPo",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllPoDetail = createAsyncThunk(
  "getAllPoDetail",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(!response.data.data.hasOwnProperty('Error') && response.status == 200)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);


export const createPo = createAsyncThunk(
  "createPo",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createInventory = createAsyncThunk(
  "createInventory",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getOtherVendorsList = createAsyncThunk(
  "getOtherVendorsList",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);