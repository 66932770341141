import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { createInventory } from "../../store/actions/po";
import add from '../../assets/add.png';
import { useLocation, useNavigate } from "react-router-dom";
import { filterName } from "../../utils/Utils";
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import { getAllRFTSDetail } from "../../store/actions/rfts";
import moment from "moment";
import ModalButton from "../../components/modalButton/modalButton";
import FillRequiredData from "./fillRequiredData";
import SearchBar from "../../components/searchBar/searchBar";
import SaveButton from "../../components/saveButton/saveButton";
import ExportButton from "../../components/exportButton";
import { uiActions } from "../../store/reducers/ui-slice";

const RequestReceiveForm = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { packSizeList, medicationTypeList } = useSelector(state => state.filler)
    const navigation = useNavigate()

    const location = useLocation();
    const { state, setOpen } = props
    const [Products, setProducts] = useState([])
    const [ProductsRef, setProductsRef] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [isExportLoading, setIsExportLoading] = useState(false);

    const [sentList, setSentList] = useState([])
    const [search, setSearch] = useState('')

    const tableHeader = ['DIN Number', 'Product Name', 'Type of Medication', 'Strenght', 'Available Quantity', 'Requested Quantity', 'Pack Size', 'Expiry']
    const verifyParam = ['diN_Master', 'productName', 'dosageValue', 'mg', 'productAvailableQty', 'requiredQuantity', 'packSizeValue', 'expiry']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Expiry', 'Sent Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'expiry', 'sentQty']

    useEffect(() => {
        getRFTSDetail()
    }, [search])


    const getRFTSDetail = () => {
        setIsLoading(true)
        setProducts([])
        setProductsRef([])
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYRFTSID",
            "data": { "RFTS_ID": state?.rftsid, "Filter": search ? search : null },
            "debug": null,
            "token": null
        };

        dispatch(getAllRFTSDetail(data)).then(res => {
            setIsLoading(false)
            const result = res.payload.data.reduce((acc, current) => {
                const product = acc.find(p => p.product_ID === current.product_ID);
                if (product) {
                    product?.records?.push({ ...current, sentQty: "", expiry: moment(current.expiryTo).format('YYYY-MM-DD') });
                } else {
                    acc.push({
                        product_ID: current.product_ID,
                        productName: current.productName,
                        requiredQuantity: current.qty,
                        expiry: moment(current.expiryTo).format('YYYY-MM-DD'),
                        diN_Master: current.diN_Master,
                        productAvailableQty: current.productAvailableQty,
                        mg: current.mg,
                        packSize_Master: current.packSize_Master,
                        packSizeValue: filterName(packSizeList, current.packSize_ID),
                        dosageValue: filterName(medicationTypeList, current.dosage_ID),
                        productDetail_ID: current.productDetail_ID,
                        productQtySent: current.productDetailQtySent,
                        sentQty: ""
                        // records: [{ ...current, sentQty: "", expiry: moment(current.expiryTo).format('YYYY-MM-DD') }]
                    });
                }
                return acc;
            }, []);
            setProducts(result)
            setProductsRef(result)
        });
    }


    const submit = () => {
        if (sentList.length > 0) {
            setIsSaveLoading(true)
            let data = {
                "sid": null,
                "lid": null,
                "reqInfo": null,
                "msgType": "INSERTSTORELATEDRECORD",
                "data": sentList.filter(val => val.sentQty != '').map(e => ({ "RFTS_ID": state?.rftsid, "Qty": e.sentQty, "ProductDetail_ID": e.productDetail_ID, "CreateBy": user?.userId, "STODate": moment(new Date()).format("YYYY-MM-DD") })),
                "debug": null,
                "token": null
            }
            console.log(data, sentList)
            dispatch(createInventory(data)).then(res => {
                setIsSaveLoading(false)
                setOpen(false)
            })
        } else {
            dispatch(uiActions.showNotification({
                status: "error",
                title: "Failed!",
                message: "Please add items to the sent list!",
            }))
        }
    }

    const addOrderList = (list) => {
        let arr = [...sentList, ...list]
        setSentList(arr)
    }

    const TableAction = ({ data }) => {
        const [localInputValue, setLocalInputValue] = useState(state.statusName == "Approved" ? Products[data.index].productQtySent : Products[data.index].sentQty || '');
        const handleInputChange = (value) => {
            if (value <= Products[data.index]?.requiredQuantity) {
                const arr = [...Products]
                arr[data.index]['sentQty'] = value
                setLocalInputValue(value);
            }
        };
        const handleChange = (qty, paramName, data) => {
            setLocalInputValue('')
            const arr = [...Products]
            arr[data.index]['requiredQuantity'] = arr[data.index]['requiredQuantity'] - qty
            let obj = {
                ...arr[data.index]
            }
            let sentArr = [...sentList]
            sentArr.push(obj)
            setSentList(sentArr)
            arr[data.index]['sentQty'] = ''
            if (arr[data.index]['requiredQuantity'] == 0) {
                arr.splice(data.index, 1)
            }
            setProducts(arr)
        }
        return (
            <Box sx={{ display: 'flex' }}>
                <TextField
                    sx={{ width: '200px' }}
                    size="small"
                    value={localInputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    type="text"
                    label="Quantity"
                    disabled={state.statusName == "Approved"}
                />
                {state.statusName != "Approved" && <IconButton className='icon' size="small" disabled={localInputValue.length == 0} onClick={() => handleChange(localInputValue, 'sentQty', data)} >
                    <img src={add} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                </IconButton>
                }
            </Box>
        )
    }

    const onRemove = (data, index) => {
        let arr = [...sentList]
        if (Products.findIndex(val => val.product_ID == data.product_ID)) {
            const proIndex = ProductsRef.findIndex(val => val.product_ID == data.product_ID)
            data['requiredQuantity'] = data.sentQty
            data['sentQty'] = ''
            Products.splice(proIndex, 0, data);
            setProducts(Products)
        } else {
            let productArr = [...Products]
            let productIndex = productArr.findIndex(val => val.product_ID == data.product_ID)
            productArr[productIndex]['requiredQuantity'] = parseInt(productArr[productIndex]['requiredQuantity']) + parseInt(arr[index]['sentQty'])
            setProducts(productArr)
        }
        arr.splice(index, 1)
        setSentList(arr)
    }
    const exportInventory = () => {
        setIsExportLoading(true)
        const MainPromise = new Promise((resolve, reject) => {
            const result = Products.map(item => ({
                DIN_Number: item.diN_Master,
                Product_Name: item.productName,
                Strength: item.mg,
                PackSize: item.packSizeValue,
                Expiry: item.expiry,
                Available_Quantity: item.productAvailableQty,
                Requested_Quantity: item.requiredQuantity,
                Sent_Quantity: item.productQtySent,
            }))
            setIsExportLoading(false)
            resolve(result)
        });
        return MainPromise;
    }

    return (
        <Card style={{ flex: 1, overflow: 'auto' }}>
            <Box >
                <Box className="poFormHeader">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='h2'>Requests From Pharmacies</Typography>
                        {state.statusName != 'Approved' && <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Sent'} />}
                    </Box>
                    <Divider sx={{ margin: '10px 0px' }} />
                </Box>
                {sentList.length > 0 && <SelectedProduct
                    title={"Selected:"}
                    selectedHeader={selectedHeader}
                    selectedParam={selectedParam}
                    list={sentList}
                    onRemove={onRemove}
                />}
                <Grid container >
                    <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchBar placeholder={'Search...'} handleChange={setSearch} />
                        <ExportButton data={exportInventory} name={`${state.pharmacyName}_${state.requestDate}`} isExportLoading={isExportLoading} />
                    </Grid>
                </Grid>
                <Card style={{ flex: 1, display: 'flex', overflowX: 'auto' }}>

                    <TableContainer tableHeader={tableHeader}
                        data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                        verifyParam={verifyParam}
                        isLoading={isLoading}
                        action={(data) => <TableAction data={data} />}
                    />
                </Card>
            </Box>
        </Card>
    );
};

export default RequestReceiveForm;


