import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPo } from "../../store/actions/po";
import { getAllGrn } from "../../store/actions/grn";
import PermissionComp from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import GrnForm from "./grnForm";
import QtyReceiveForm from "./qtyReceiveForm";

const Grn = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const location = useLocation();
    const { state } = props

    const { grn } = useSelector(state => state.grn)
    const { user } = useSelector(state => state.auth)

    const [open, setOpen] = React.useState(false);

    const [Grn, setGrn] = useState([])

    const tableHeader = ['GRN No']
    const verifyParam = ['grN_No']
    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLGRNMASTER",
            "data": { "CompanyID": user?.businessId ? user?.businessId : null, "PO_ID": state.id },
            "debug": null,
            "token": null
        }
        dispatch(getAllGrn(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        grn?.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setGrn(arr)

    }, [grn])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >GRN</Typography>
                <PermissionComp list={['PO', 'grno', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Product'} type={'Button'}>
                        <GrnForm setOpen={setOpen} poID={state.id} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Grn}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={(data) => <TableAction
                        podetail={state} item={data.id} vendorID={state.vendor_ID}
                    // gotoReceivedqty={() => navigation('quantityReceived', { state: { podetail: state,item: data.id, vendorID: state.vendor_ID } })} 
                    />}
                    actionText={"Quantity Received"} />
            </Card>
        </>
    );
};

export default Grn;



const TableAction = (props) => {
    const { podetail, item, vendorID } = props
    const [openGRN, setOpenGRN] = React.useState(false);
    const navigation = useNavigate()
    return (
        <ModalButton open={openGRN} setOpen={setOpenGRN} tooltip={'Quantity Received'} type={'button'}>
            <QtyReceiveForm podetail={podetail} item={item} vendorID={vendorID} setOpen={setOpenGRN} />
        </ModalButton>
        // <Button size="medium" variant="contained" color="primary" onClick={() => navigation('quantityReceived', {state})}>Quantity Received</Button>
    )
}
