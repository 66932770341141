import { Box, Button, Divider, Grid, IconButton, Modal, Tooltip } from "@mui/material";
import React, { Children, useContext, useEffect } from "react";
import Card from "../card/Card";
import HeadingTitle from "../headingTitle/headingTitle";
import './modalButton.scss'
import { useSelector } from "react-redux";
import ActionButton from "../actionButton";

const style = {
    top: '50%',
    left: '50%',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ModalButton = (props) => {
    const { children, open, setOpen, tooltip, img, type, disabled } = props


    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }


    return (
        <>
            {type == 'icon' ? <Tooltip title={tooltip}>
                <IconButton disabled={disabled} onClick={handleOpen} sx={{ width: '40px', margin: '0px auto' }}>
                    <img src={img} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                </IconButton>
            </Tooltip> :
                <ActionButton label={tooltip} onClick={handleOpen} />
            }
            {open && <Box style={{ height: '100vh', width: '100vw', background: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: '0px', left: '0px', zIndex: '1000', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Box style={style}>
                    <Card classes='modalCardContainer' >
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <HeadingTitle title={tooltip} handleClose={handleClose} />

                        </Box>
                        {children}
                    </Card>
                </Box>
            </Box>}
        </>
    );
};

export default ModalButton;
